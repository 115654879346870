/**
 * Middleware sprawdzający czy użytkownik ma dostęp do danej funkcjonalności
 * na podstawie app_feature_id
 *
 * @param {number} requiredFeatureId - ID wymaganej funkcjonalności
 * @returns {Function} Middleware function
 */
import store from "../../store";

export const requireFeatureAccess = (requiredFeatureId) => (to, from, next) => {
  const user = store.getters["employees/currentUser"];
  const featureIds = user?.app_role_details?.app_feature_id || [];

  if (featureIds.includes(requiredFeatureId)) {
    next();
  } else {
    // Przekieruj do strony głównej jeśli brak dostępu
    next("/");
    // Pokaż komunikat o braku dostępu
    store.dispatch("cloudMessage/showMessage", {
      message: "Brak dostępu do tej funkcjonalności",
      type: "error",
      duration: 5000,
    });
  }
};
