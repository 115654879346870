import axios from "axios";
import { API_CONFIG } from "./apiConfig";
import {
  createRequestInterceptor,
  createResponseInterceptor,
} from "./modules/interceptors";

export const createApi = (configKey) => {
  const config = API_CONFIG[configKey];
  if (!config) {
    throw new Error(`Invalid API configuration key: ${configKey}`);
  }

  const instance = axios.create({
    baseURL: config.baseURL,
    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
      ...(process.env.NODE_ENV === "development" && {
        "X-Data-Source": "development",
        "X-Branch": import.meta.env.VITE_XANO_BRANCH,
      }),
    },
  });

  instance.interceptors.request.use(
    (config) => {
      return createRequestInterceptor(true)(config);
    },
    (error) => Promise.reject(error)
  );

  instance.interceptors.response.use(
    createResponseInterceptor(false).success,
    createResponseInterceptor(false).error
  );

  return instance;
};

export const api = createApi("EMPLOYEES");
