<template>
  <div
    v-if="isVisible"
    class="fixed top-0 left-0 right-0 bg-gray-900/90 text-gray-200 shadow-lg backdrop-blur-sm z-50 transition-all duration-200 hover:bg-gray-900"
  >
    <div class="flex items-center justify-between px-4 py-1 text-xs">
      <div class="flex items-center gap-4">
        <div class="flex items-center gap-2">
          <CodeBracketIcon class="w-4 h-4 text-dynamic-orange" />
          <span>v{{ version }}</span>
        </div>
        <div class="flex items-center gap-2">
          <ClockIcon class="w-4 h-4 text-dynamic-orange" />
          <span>{{ buildTime }}</span>
        </div>
        <div class="flex items-center gap-2">
          <ComputerDesktopIcon class="w-4 h-4 text-dynamic-orange" />
          <span>{{ mode }}</span>
        </div>
      </div>
      <div>
        <button
          @click="isVisible = false"
          class="p-1 hover:text-dynamic-orange transition-colors duration-200"
        >
          <XMarkIcon class="w-4 h-4" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import {
  CodeBracketIcon,
  ClockIcon,
  ComputerDesktopIcon,
  XMarkIcon,
} from "@heroicons/vue/24/outline";

export default {
  name: "DebugBar",
  components: {
    CodeBracketIcon,
    ClockIcon,
    ComputerDesktopIcon,
    XMarkIcon,
  },
  setup() {
    const isVisible = ref(true);
    const version = process.env.VUE_APP_VERSION || "1.0.0";
    const buildTime = computed(() => {
      const date = process.env.BUILD_TIME
        ? new Date(process.env.BUILD_TIME)
        : new Date();
      return date.toLocaleString("pl-PL", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      });
    });
    const mode = process.env.NODE_ENV;

    return {
      isVisible,
      version,
      buildTime,
      mode,
    };
  },
};
</script>
