export default [
  {
    path: "/trainer-calendar",
    name: "trainer-calendar",
    component: () => import("@/views/trainerCalendar/TrainerCalendar.vue"),
    meta: {
      requiresAuth: true,
      title: "Kalendarz trenerów",
    },
  },
];
