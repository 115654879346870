<script setup>
import { useStore } from "vuex";
import Intercom from "@intercom/messenger-js-sdk";

// Initialize Intercom only in production
if (import.meta.env.PROD) {
  const store = useStore();
  const user = store.state.employees.user;

  Intercom({
    app_id: import.meta.env.VITE_INTERCOM_APP_ID,
    user_id: user.id,
    name: `${user.first_name} ${user.last_name}`,
    email: user.email,
    created_at: Math.floor(user.created_at / 1000),
    // Custom attributes z prostymi nazwami
    venue_id: user.current_venue_id,
    venue_name:
      user._employee_venue_profiles?.find(
        (v) => v.venue_id === user.current_venue_id
      )?.name || "Unknown Venue",
    user_role: user.app_role_details?.name,
    last_login: Math.floor(user.last_login / 1000),
    is_active: user.is_active,
  });
}
</script>

<template>
  <!-- Intercom messenger will be injected into the DOM automatically -->
</template>

<style>
/* Ustawienie niższego z-indexu dla wszystkich komponentów Intercom */
#intercom-container,
.intercom-launcher-frame,
.intercom-messenger-frame,
.intercom-lightweight-app {
  z-index: 39 !important;
}
</style>
