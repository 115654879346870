import { requireFeatureAccess } from "../middleware/featureAccess";

export default [
  {
    path: "/marketing-automation",
    name: "MarketingAutomation",
    component: () => import("@/views/marketingAutomation/home.vue"),
    meta: {
      requiresAuth: true,
      title: "Marketing Automation",
    },
    beforeEnter: requireFeatureAccess(28), // ID 28 dla marketing automation
  },
];
