<template>
  <div class="app-container" :class="{ 'pt-6': isDevelopment }">
    <DebugBar v-if="isDevelopment" />
    <TopLoader />

    <!-- Główny kontener z układem kolumnowym -->
    <div class="flex h-screen overflow-hidden">
      <!-- Navbar jako lewa kolumna -->
      <Navbar v-if="shouldShowNavbar" />

      <!-- Główna zawartość po prawej -->
      <main class="flex-grow overflow-auto">
        <router-view></router-view>
      </main>
    </div>

    <CloudMessage />
    <ModalContainer />
    <IntercomMessenger />
  </div>
</template>

<script>
import { computed, onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import Navbar from "./components/navbar/Navbar.vue";
import CloudMessage from "./components/addons/cloudMessage.vue";
import TopLoader from "./components/TopLoader.vue";
import ModalContainer from "./components/ModalContainer.vue";
import DebugBar from "./components/debug/DebugBar.vue";
import IntercomMessenger from "./components/IntercomMessenger.vue";

export default {
  name: "App",
  components: {
    Navbar,
    CloudMessage,
    TopLoader,
    ModalContainer,
    DebugBar,
    IntercomMessenger,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const isAuthenticated = computed(
      () => store.getters["employees/isAuthenticated"]
    );
    const isLoginPage = computed(() => route.path === "/login");
    const hasSelectedVenue = computed(
      () => store.getters["employees/hasSelectedVenue"]
    );

    const shouldShowNavbar = computed(
      () =>
        isAuthenticated.value && !isLoginPage.value && hasSelectedVenue.value
    );

    const isDevelopment = computed(
      () => process.env.NODE_ENV === "development"
    );

    const startLoading = () => {
      store.commit("app/SET_LOADING", true);
    };

    const stopLoading = () => {
      store.commit("app/SET_LOADING", false);
    };

    onMounted(() => {
      router.beforeEach((to, from, next) => {
        startLoading();
        next();
      });

      router.afterEach(() => {
        stopLoading();
      });
    });

    onUnmounted(() => {
      // Usuwamy nasłuchiwanie zdarzeń routera, jeśli to konieczne
    });

    return {
      shouldShowNavbar,
      isDevelopment,
    };
  },
};
</script>

<style>
html,
body,
#app {
  height: 100%;
  margin: 0;
  padding: 0;
}

.app-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

/* Resetujemy wszystkie domyślne marginesy i paddingi */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
</style>
