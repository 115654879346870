export default {
  user: null,
  isAuthenticated: false,
  authToken: localStorage.getItem("authToken") || null,
  availableVenues: [],
  currentVenueId: localStorage.getItem("currentVenueId") || null,
  currentAppRoleId: null,
  message: null,
  error: null,
  hasMagicToken: false,
};
