/**
 * Inicjalizacja Microsoft Clarity
 */
export function initClarity() {
  // Only initialize Clarity in production
  if (import.meta.env.MODE !== "production") {
    return;
  }

  const clarityId = import.meta.env.VITE_CLARITY_ID;
  if (!clarityId) {
    console.warn("Microsoft Clarity ID is not configured");
    return;
  }

  (function (c, l, a, r, i, t, y) {
    c[a] =
      c[a] ||
      function () {
        (c[a].q = c[a].q || []).push(arguments);
      };
    t = l.createElement(r);
    t.async = 1;
    t.src = "https://www.clarity.ms/tag/" + i;
    y = l.getElementsByTagName(r)[0];
    y.parentNode.insertBefore(t, y);
  })(window, document, "clarity", "script", clarityId);
}
