import { api } from "../apiFactory";

export const employeeApi = {
  fetchEmployees: () => api.get(`employee`),
  getEmployeeRoles: () => api.get(`app/role`),
  createNewEmployee: (data) => api.post(`employee`, data),
  deleteEmployee: (id) => api.delete(`employee/${id}`),
  updateEmployee: (id, data) => api.patch(`employee/${id}`, data),
  getEmployeeDetails: (id) => api.get(`employee/${id}`),
  updatePassword: (employeeId, password) =>
    api.patch(`/employee/password_change`, {
      password: password,
      employee_id: employeeId,
    }),
};
